<template lang="pug">
  div
    v-row
      v-col(cols="3")
        create-voucher-detail(
          v-model="voucherDetailData"
          :edit-data="editVoucherDetailData"
        )
        v-row
          v-col(cols="12" v-if="!editVoucherDetailMode")
            v-btn(
              color="green"
              small
              block
              dark
              @click="insertVoucherDetail"
            )
              span add
          v-col(cols="6" v-if="editVoucherDetailMode")
            v-btn(
              color="yellow darken-2"
              small
              block
              dark
              @click="updateData"
              :loading="voucherDetailPosting"
            )
              span save
          v-col(cols="6" v-if="editVoucherDetailMode")
            v-btn(
              color="red"
              small
              dark
              block
              @click="editVoucherDetailData = {}"
            )
              span cancel
      v-col(cols="9")
        table-voucher-detail(
          :items="voucherDetails"
          v-model="editVoucherDetailData"
        )
</template>
<script>
import voucherDetailRepository from '@/repositories/voucher-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [voucherDetailPostVars, voucherDetailPostVarNames] = requestVars({ identifier: 'voucher-detail', request: 'post', hasData: false })
const voucherDetailPostHandler = new VueRequestHandler(null, voucherDetailPostVarNames)

const inputVars = () => ({
  voucherDetailData: {},
  voucherDetails: [],
})

export default {
  name: 'Vouchers',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    createVoucherDetail: () => import('./Create'),
    tableVoucherDetail: () => import('./Table'),
  },
  data() {
    return {
      ...inputVars(),
      ...voucherDetailPostVars,
      editVoucherDetailData: {},
    }
  },
  computed: {
    editVoucherDetailMode() {
      return !this.$objectEmpty(this.editVoucherDetailData)
    },
  },
  created() {
    this.initWebsocket()
  },
  watch: {
    voucherDetails(value) {
      this.$emit('input', value)
    },
    value(value) {
      this.voucherDetails = value
    },
    editVoucherDetailMode(value) {
      this.voucherDetailData = value
    },
  },
  methods: {
    initWebsocket() {
      if (!this.editMode) return
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    updateData() {
      if (this.editMode) {
        this.updateVoucherDetail()
        return
      }
      this.updateVoucherDetailsArrayItem()
    },
    updateVoucherDetail() {
      const handler = voucherDetailPostHandler
      const repository = voucherDetailRepository.update
      const { id, data } = this.getVoucherDetailData()
      handler.setVue(this)
      handler.execute(repository, [id, data], this.updateVoucherDetailStatusOk)
    },
    updateVoucherDetailStatusOk() {
      this.editVoucherDetailData = {}
    },
    dbUpdateEvent({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'VoucherDetail') {
        this.voucherDetailDBUpdateEvent(data)
      }
    },
    dbStoreEvent({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'VoucherDetail') {
        this.voucherDetailDBStoreEvent(data)
      }
    },
    updateVoucherDetailsArrayItem() {
      const { voucherDetailData } = this
      this.voucherDetails = this.voucherDetails.map(item => {
        if (item.id === voucherDetailData.id) return { ...voucherDetailData }
        return { ...item }
      })
    },
    voucherDetailDBStoreEvent(data) {
      const { voucherId } = this.$route.params
      data = Window.objectChangeKeyCase(data)
      if (voucherId !== data.voucherId) return
      this.voucherDetails.push(data)
    },
    voucherDetailDBUpdateEvent(data) {
      data = Window.objectChangeKeyCase(data)
      this.voucherDetails = this.voucherDetails.map(item => {
        if (item.id === data.id) return { ...data }
        return { ...item }
      })
    },
    insertVoucherDetail() {
      if (this.editMode) {
        this.storeVoucherDetail()
        return
      }
      this.voucherDetails.push(this.voucherDetailData)
      this.voucherDetailData = {}
    },
    storeVoucherDetail() {
      const handler = voucherDetailPostHandler
      const repository = voucherDetailRepository.store
      const { data } = this.getVoucherDetailData()
      handler.setVue(this)
      handler.execute(repository, [data])
    },
    getVoucherDetailData() {
      const { voucherDetailData } = this
      const included = ['accountCodeId', 'costCenterId']
      const excluded = ['accountCode', 'costCenter']
      const data = Object.keys(voucherDetailData)
        .filter(key => !excluded.includes(key))
        .concat(included)
        .reduce((result, key) => {
          const snakeKey = key.camelToSnakeCase()
          if (key === 'accountCodeId') {
            result[snakeKey] = voucherDetailData.accountCode.id
            return result
          }
          if (key === 'costCenterId') {
            result[snakeKey] = voucherDetailData.costCenter.id
            return result
          }
          result[snakeKey] = voucherDetailData[key]
          return result
        }, {})
      const { id } = voucherDetailData
      return { data, id }
    },
  },
}
</script>
